import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Inductabend is a leader in the application of induction technologies
          for bending, forming and treatment of pipes and structural sections.
        </p>
        <p>
          Capabilities cover a range of diverse induction technologies;
          including the internationally recognised CNC Cojafex PB 850 SE
          induction bending machine (2 off), as well as other specialised
          machines each with unique capabilities. Inductabend has the experience
          and capabilities to achieve a high level of process control and close
          tolerances in the most demanding applications. The range of
          technologies offered demonstrates a commitment to providing the best
          possible induction bending, shaping and heat treatment services at
          very competitive rates across a wide range of materials, including
          carbon, alloy and HSLA steels, all grades of stainless steels, duplex
          and super duplex, nickel alloys, titanium and clad pipe etc.
        </p>
      </div>
    </div>
  )
}

export default PageContent
